import {
  useAuthActions,
  useIsAuthenticated,
  useIsAuthenticationInProgress,
  useLoggedInMember
} from "@/modules/auth/states";
import ZaniaLogoLoader from "@components/page-loader";
import { useEffect, useMemo, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AppProtectedRoutes, AppUnprotectedRoutes } from "../routes";
// import useLoadDataOnAppLoad from "@/shared/hooks/use-load-data-start";
import { authChannel } from "@/infra/auth/broadcast";
import { AuthMessageEvent } from "@/infra/auth/type";
import { getMemberDetailsWithOrganization, getSettingsApi, getTeam, refreshAuthToken } from "@/modules/auth/requests";
import { setLoginStep, useLoginActions, useLoginStep } from "@/modules/auth/states/login";
import { DiscoveredOrganization, LOGIN_STEP } from "@/modules/auth/types";
import { setTeam } from "@/modules/team/states";
import { deleteCookie, getCookie } from "@/shared/utils/cookie-helper";
import parsePromise from "@/shared/utils/parsePromise";
import { Worker } from "@react-pdf-viewer/core";
import { version as pdfJsVersion } from "pdfjs-dist";
import { useUserStateSelector } from "@/shared/states/user";


function App() {
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticationInProgress = useIsAuthenticationInProgress();
  const { setAuth } = useAuthActions();
  const [isSessionChecked, setIsSessionChecked] = useState(false); 
  const setAIResponseType = useUserStateSelector.useSetAIResponseType();
  const setUserResponseType = useUserStateSelector.useSetUserResponseTypes();
  const loggedInMember = useLoggedInMember();
  const loginStep = useLoginStep();
  const [hasFetchedTeam, setHasFetchedTeam] = useState(false);
  const [hasFetchedSettings, setHasFetchedSettings] = useState(false);
  const routes = useMemo(() => {
    return isAuthenticated &&
      !isAuthenticationInProgress &&
      loginStep !== LOGIN_STEP.SELECT_ORG
      ? AppProtectedRoutes
      : AppUnprotectedRoutes;
  }, [isAuthenticated, isAuthenticationInProgress, loginStep]);

  const {
    setDiscoveredOrganizations,
  } = useLoginActions();

  const router = useMemo(() => {
    return createBrowserRouter(routes);
  }, [routes]);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      const session = getCookie('intermediate_session_token');
  
      if (session && !hasFetched) {
        setHasFetched(true); // Prevent future API calls
  
        const [error, member] = await parsePromise(getMemberDetailsWithOrganization());
  
        if (member?.member) {
          setAuth({
            isAuthenticated: true,
            isAuthenticationInProgress: false,
            loggedInMember: member?.member,
            loggedInOrganization: member?.organization,
          });
          authChannel.postMessage("loggedIn");
  
          const [err, settings] = await parsePromise(getSettingsApi());
          if (settings?.data?.ui_response_quality) {
            setAIResponseType(settings.data.ui_response_quality);
            setUserResponseType(settings.data.user_response_quality);
          }
        } else {
          deleteCookie('intermediate_session_token');
          authChannel.postMessage("loggedOut");
          setLoginStep(LOGIN_STEP.MAIN);
          setAuth({
            isAuthenticated: false,
            isAuthenticationInProgress: false,
            loggedInMember: null,
            loggedInOrganization: null,
          });
        }
      } else {
        setLoginStep(LOGIN_STEP.MAIN);
        setAuth({
          isAuthenticated: false,
          isAuthenticationInProgress: false,
          loggedInMember: null,
          loggedInOrganization: null,
        });
        authChannel.postMessage("loggedOut");
      }
  
      setIsSessionChecked(true);
    };
  
    if (!hasFetched) {
      void checkSession(); // Ensure it's only called once
    }
  }, [isAuthenticated]); // Keep dependencies for reactivity
  

  const getTeamFn = async() => {
    const [error, members] = await parsePromise(getTeam());
      if (members && members.team_members) {
        setTeam(members.team_members);
      }
  }

  useEffect(() => {
    // Add worker for refresh token
    const worker = new window.Worker(new URL("/worker.js", import.meta.url));
  
    // If the user is authenticated, start the worker
    if (loggedInMember?.member_id) {
      if(!hasFetchedTeam) {
        setHasFetchedTeam(true);
      }
      void getTeamFn();
      worker.postMessage("start");
  
      worker.onmessage = async (event) => {
        if (event.data === "refresh") {
          const [error, data] = await parsePromise(refreshAuthToken());
          if (error) {
            console.error(error?.message);
            return;
          }
        }
      };
    } else {
      // If the user is not authenticated, terminate the worker
      worker.terminate();
      return;
    }
  
    // Cleanup function
    return () => {
      worker.terminate();
    };
  
  }, [loggedInMember]);
  
  // broadcast events handler to sync multiple tabs
  useEffect(() => {
    const handleMessage = (event: AuthMessageEvent) => {
      if (event.data === "loggedIn") {
        setAuth({
          isAuthenticated: true,
          isAuthenticationInProgress: false
        });
        setLoginStep(LOGIN_STEP.MAIN);
      } else if (event.data === "loggedOut") {
        setLoginStep(LOGIN_STEP.MAIN);
        setAuth({
          isAuthenticated: false,
          isAuthenticationInProgress: false,
        });
      } else if (event.data === "switchedOrganization") {
        const checkSession = async () => {
          const [error, member] = await parsePromise(getMemberDetailsWithOrganization());
          if (member) {
            setAuth({
              isAuthenticated: true,
              isAuthenticationInProgress: false,
              loggedInMember: member?.member,
              loggedInOrganization: member?.organization,
            });
          }
        };
        checkSession();
      } else if (event.data === "selectOrganization") {
        const orgs = localStorage.getItem("discoveredOrgs");
        const orgList = JSON.parse(orgs || "[]");
        if (orgList?.length) {
          setDiscoveredOrganizations(orgList as DiscoveredOrganization[])
        }
        localStorage.removeItem("discoveredOrgs")

        setLoginStep(LOGIN_STEP.SELECT_ORG);
      }
    };

    authChannel.onmessage = handleMessage;

    return () => {
      authChannel.onmessage = null; // ✅ Just remove the listener, don't close channel
    };
  }, []);

  if (isAuthenticationInProgress || !isSessionChecked) {
    return <ZaniaLogoLoader />;
  }

  return (
    <Worker
      workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJsVersion}/pdf.worker.min.js`}
    >
      <RouterProvider router={router} fallbackElement={<ZaniaLogoLoader />} />
    </Worker>
  );
}

export default App;
