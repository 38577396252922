import { VENDOR_PREDEFINED_COMPLIANCE_CERTS } from "@/modules/agent/constants/vendorAssessmentConstants";
import { VendorRiskResponseItemForVendorOverview } from "@/modules/agent/types/vendorOverviewSection";
import { ZButton } from "@/shared/components/button";
import { ZInput } from "@/shared/components/input";
import {
  ZModal,
  ZModalBody,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import { addNotification } from "@/shared/states/notification";
import { useEffect, useState } from "react";
import { containsSpecialCharacters } from "../../upload-dialog/util";
import { useVendorRiskAssessmentStore } from "../useVendorRiskAssessment/useVendorRiskAssessmentStore";

const AddCertificatePopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const {
    setVendorOverview,
    vendorOverview,
  } = useVendorRiskAssessmentStore.getState();

  const handleSubmitClick = () => {
    const temp = [...vendorOverview];

    const foundItem = temp.find(
      (singleOverviewData) => singleOverviewData.key === "compliance"
    ) as VendorRiskResponseItemForVendorOverview<"compliance">;

    if (!foundItem) {
      addNotification({
        type: "error",
        title: "Could not add certification to the list",
        message: "Please reload the page and try again",
      });
      return;
    }

    if (
      VENDOR_PREDEFINED_COMPLIANCE_CERTS.some(
        (cert) => cert.label.toLowerCase() === name.toLowerCase()
      ) ||
      foundItem.customer_added_certifications?.some(
        (cert: string) => cert.toLowerCase() === name.toLowerCase()
      )
    ) {
      addNotification({
        type: "warn",
        title: `${name} is already present in the list`,
        message: "Please use the checkbox to select it",
      });
      return;
    }

    // (foundItem.value.value as string[]).push(name);

    foundItem.customer_added_certifications = [
      ...(foundItem.customer_added_certifications ?? []),
      name,
    ];

    setVendorOverview(temp);

    setName("");
    setShowPopup(false);
  };

  const handleOnClose = () => {
    setName("");
    setShowPopup(false);
  };

  // i would to reset the name state wheneve the popup open state changes
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setName("");
  }, [showPopup]);

  return (
    <>
      <ZButton
        variant="bordered"
        color="primary"
        className="rounded-md w-fit text-xs leading-5 px-3 py-1 h-7"
        onClick={() => setShowPopup(true)}
      >
        Add Certification
      </ZButton>

      {showPopup && (
        <ZModal
          isOpen={true}
          onOpenChange={handleOnClose}
          aria-label="Add Sub Services"
          className="max-h-[calc(100vh-150px)] overflow-y-auto"
        >
          <ZModalContent className="max-w-lg">
            <ZModalHeader className="flex text-lg flex-col w-full items-center pb-0">
              <p>Add Certificate</p>
            </ZModalHeader>

            <ZModalBody className="pt-0 overflow-auto flex gap-2">
              <ZInput
                label="Certficate Name"
                labelPlacement="outside"
                variant="faded"
                placeholder="Add name"
                value={name}
                onChange={(e) => setName(e.target.value.trimStart().toUpperCase())}
                isInvalid={containsSpecialCharacters(name.trim()) || name.trim().length > 20}
                errorMessage={containsSpecialCharacters(name.trim()) ? "Special character are not allowed" : name.trim().length > 20 ?  "Input cannot exceed 20 characters." : undefined}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  shadow-none rounded-md"
              />
            </ZModalBody>
            <ZModalFooter>
              <ZButton
                variant="bordered"
                onClick={handleOnClose}
                className="px-3 py-1 h-auto text-sm"
              >
                Discard
              </ZButton>
              <ZButton
                type="submit"
                onClick={handleSubmitClick}
                isDisabled={name.trim().length === 0 || containsSpecialCharacters(name.trim()) || name.trim().length > 20}
                disabled={name.trim().length === 0 || containsSpecialCharacters(name.trim()) || name.trim().length > 20}
                className="px-3 py-1 h-auto text-sm"
              >
                Submit
              </ZButton>
            </ZModalFooter>
          </ZModalContent>
        </ZModal>
      )}
    </>
  );
};

export default AddCertificatePopup;
