import { post } from "@/infra/rest";
import { getZaniaSocket } from "@/infra/sockets";
import type {
  DoraRagCreateRequest,
  FairRagCreateRequest,
  MultiFileVendorRagCreateRequest,
  NistAIRMFRagCreateRequest,
  NistCsf2RagCreateRequest,
  QARagCreateRequest,
  Soc2RagCreateRequest,
} from "@/modules/sessions/types";

export const emitRagCreate = (
  data:
    | FairRagCreateRequest
    | Soc2RagCreateRequest
    | NistCsf2RagCreateRequest
    | NistAIRMFRagCreateRequest
    | DoraRagCreateRequest
    | MultiFileVendorRagCreateRequest 
    | QARagCreateRequest,
    

  callback?: (...args: any[]) => void,
) => {
  const RAG_IDENTIFIER = "task:rag:create";
  const socket = getZaniaSocket();

  socket.emit(RAG_IDENTIFIER, data, callback);
};

interface RAGDataRequest {
  task: string;
  output_format: string;
  response_quality: string;
  agent_session_id: string;
  use_for_ai_training?: boolean;
}

interface RAGResponse {
  success: boolean;
  message: string;
  data: {
    file_url: string;
    output_format: string;
  };
}

export const getRAGExcelFromJSON = (data: RAGDataRequest) => {
  return post<RAGDataRequest, RAGResponse>({
    url: "/v1/rag/converter",
    data,
  });
};
