import { AiResponseType } from "@/shared/types/user";
import { WorkBook } from "xlsx";
import {
  ConfidenceTypes,
  ResponseModes,
  ReviewSourceTypes,
  dataNotFound,
} from ".";
import { HighlightData } from "./excel-mapper";

export type Question = {
  question_text: string;
  question_id: string;
  answer: string[] | string;
  confidence: ConfidenceTypes;
  sources: string[];
  justification: string;
  comment: string;
  id?: string;
  approved?: boolean;
  edited?: boolean;
};

export type QuestionReviewReponse = {
  question_text: string;
  question_id: string;
  answer: string[] | string;
  confidence: ConfidenceTypes;
  sources: ReviewSourceTypes[];
  justification: string;
  comment: string;
  id?: string;
  approved?: boolean;
};

export type Merges = {
  s: {
    r: number;
    c: number;
  };
  e: {
    r: number;
    c: number;
  };
}[];

export interface SheetData {
  data: string[][];
  merges: Merges;
}

export type QuestionnaireMapping = Record<string, MappingData>;

export interface ColumnMappingData {
  columnIndex: number;
  columnLetter: string;
  metaData?: HighlightData;
}

export interface AnswerColumnMappingData extends ColumnMappingData {
  answerType?: string;
}

export enum MappingType {
  QUESTIONS = "questions",
  COMMENTS = "comments",
  ANSWERS = "answers",
  QUESTION_START_ROW = "questionStartRow",
  QUESTION_TYPE = "questionType",
}

export interface MappingData {
  questionStartRow: number | null;
  questions: ColumnMappingData[];
  comments: ColumnMappingData[];
  answers: AnswerColumnMappingData[];
  questionType: ColumnMappingData[];
  status?: SheetStatus;
}

export enum SheetStatus {
  IGNORED = "ignored",
  ERROR = "error",
}

export interface QuestionnaireFiller {
  sheet_name: string;
  sheet_index: number
  metadata: QuestionnaireFillerData;
}

export interface QuestionnaireFillerData {
  questions_start_row: number | null;
  question_col_index: number;
  answer_col_indexes: number[];
  comment_col_index: number | null;
  answer_values: string[] | null;
  question_type_column: number | null;
}

export interface QuestionnaireFileData {
  url: string;
  fileName: string;
  workbook: WorkBook | null;
  sheetNames: string[];
  sheetData: Record<string, SheetData>;
}

export interface SheetData {
  data: string[][];
  merges: Merges;
}

export interface KnowledgeBaseFiles {
  url: string;
  fileName: string;
}

export interface GetExcelFromJSONRequest {
  excel_url: string;
  json_url: string;
  excel_metadata: QuestionnaireFiller[];
  client_id: string;
}
export interface GetExcelFromJSONResponse {
  success: boolean;
  message: string;
  payload: any;
}

export interface QAFillerRequest {
  response_quality?: AiResponseType
  required_document_type?: string;
  is_light?: boolean;
  kb_url?: string;
  multiple_source_urls?: string[];
  source_urls?: string[];
  excel_url: string;
  excel_meta_data?: QuestionnaireFiller[];
}
export interface QaStructure {
  excel_url: string;
}

export interface QuestionnaireStructureRequest {
  agent_session_id: string;
  agent_session_step_id: string;
  qa_structure: QaStructure;
  response_mode: ResponseModes;
  response_quality: AiResponseType;
}

export interface QuestionnaireFillerRequest {
  agent_session_id?: string;
  agent_session_step_id?: string;
  qa_filler: QAFillerRequest;
}

export interface QuestionnaireStructure {
  sheet_number: number;
  response: SheetMappingResponse;
}

export interface SheetMappingResponse {
  questions: ColumnMappingFromAPI | null | typeof dataNotFound;
  answers: ColumnMappingFromAPI | null | typeof dataNotFound;
  comments: ColumnMappingFromAPI | null | typeof dataNotFound;
  answerType: ColumnMappingFromAPI | null | typeof dataNotFound;
  questionStartRow: number | null;
}

export interface ColumnMappingFromAPI {
  columnLetters: string[];
  columnNames: string[];
}
