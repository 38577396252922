import { getZaniaSocket } from "@/infra/sockets";
import { useVendorRiskAssessmentStore } from "@/modules/agent/components/VendorRiskAssessment/useVendorRiskAssessment/useVendorRiskAssessmentStore";
import { getAgentData, getAgentStateActions } from "@/modules/agent/states";
import type {
  FairAgentMainData,
  GapAssessmentTypes,
  MultiVendorAssessmentTypes,
  ReviewResponseData,
  RiskAgentMainData,
  SOC2GapMainData
} from "@/modules/agent/types";
import { AGENT_TYPES, RiskAssessmentTypes } from "@/modules/agent/types";
import type { ControlUpdatePayload } from "@/modules/agent/types/events";
import type { QuestionnaireStructure } from "@/modules/agent/types/questionnaire";
import { VendorRiskSectionEnum } from "@/modules/agent/types/vendorAssessment";
import type { VendorRiskResponseItemForSecurityAssessment } from "@/modules/agent/types/vendorSecurityAssessment";
import { processFileForDoraAudit } from "@/modules/agent/use-cases/dora.use-case";
import { processFileForFairReview } from "@/modules/agent/use-cases/fair.use-case";
import {
  processFileForQuestionaire,
  processManualMapping
} from "@/modules/agent/use-cases/quesitionnaire.use-case";
import { processFileForNistAiRMFRiskReview, processFileForNistRiskReview } from "@/modules/agent/use-cases/risk.use-case";
import { processFileForSOC2Type2Audit } from "@/modules/agent/use-cases/soc2.use-case";
import { processFileForVendorAssessmentResponse } from "@/modules/agent/use-cases/vendor.use-case";
import { getOrderedKeys } from "@/modules/agent/utils/get-order-keys";
import { getRenderType } from "@/modules/agent/utils/get-render-type";
import { getFieldTitle } from "@/modules/agent/utils/getFieldTitle";
import { useLoggedInMember } from "@/modules/auth/states";
import {
  AgentSessionStatus,
  AgentSessionStepType
} from "@/modules/sessions/types";
import useSocket from "@/shared/hooks/use-socket";
import { useCallback, useEffect } from "react";
export interface QAStructureCompletedResponse {
  agent_session_id: string;
  success: boolean;
  qa_structure: QuestionnaireStructure[];
}

export interface QAReviewResponseCompletedResponse {
  uid: string;
  success: boolean;
  agent_session_id: string;
  qa_filler: {
    filled_questionnaire_url: string;
  };
}

export interface RiskAssessmentComplete {
  agent_session_id: string;
  agent_session_step_id: string;
  success: boolean;
  risk_assessment_response: {
    file_url: string;
    file_type: string;
  };
}

export interface FairAssessmentComplete {
	agent_session_id: string;
	agent_session_step_id: string;
	success: boolean;
	fair_ra: {
		file_url: string;
		file_type: string;
	};
}



export interface DoraAssessmentComplete {
  uid: string;
  success: boolean;
  dora_assessment_response: {
    file_url: string;
    file_type: string;
  };
}

export interface RagAuditComplete {
  agent_session_id: string;
  agent_session_step_id: string;
  success: boolean;
  rag_output: {
    file_url: string;
    file_type: string;
  };
  task: string;
  assessment_type: string;
}

const isIdInUrl = (id: string) => {
  const idInUrl = new URL(window.location.href).toString().includes(id);
  return idInUrl;
};

export const useAppSocket = () => {
	const { addListener, removeListener, isConnected } = useSocket("main", () =>
		getZaniaSocket(),
	);

  const loggedInMember = useLoggedInMember();

  const onQAStructureComplete = useCallback(
		async ({
			success,
			qa_structure,
			agent_session_id,
		}: QAStructureCompletedResponse) => {
      if (!success || !isIdInUrl(agent_session_id)) return;
      await processManualMapping({
        agent_session_id,
        structureData: qa_structure,
      });
    },
    [],
  );


  const onRagAuditComplete = useCallback(
		async ({
			success,
			rag_output,
			agent_session_id,
			task,
			assessment_type
		}: RagAuditComplete) => {
      if (!success || !rag_output || !isIdInUrl(agent_session_id)) return;
			if(assessment_type === "reassessment") return;
			switch (task) {
				case "soc2_type2_audit":
				case "soc2_type2":
				case "soc2_type1":
					await processFileForSOC2Type2Audit(
						rag_output.file_url,
						agent_session_id,
					);
          break;
        case "dora_assessment":
          await processFileForDoraAudit(rag_output.file_url, agent_session_id);
          break;
        case "nist_csf_2_risk_assessment":
					await processFileForNistRiskReview(rag_output.file_url, agent_session_id);
          break;
        case "nist_ai_rmf":
					await processFileForNistAiRMFRiskReview(rag_output.file_url, agent_session_id);
          break;
        case "fair_ra":
					await processFileForFairReview(rag_output.file_url, agent_session_id);
					break;
        case "multi_file_vendor_assessment":
					await processFileForVendorAssessmentResponse(
						rag_output.file_url,
						agent_session_id,
            true
					);
          break;
        case "qa_filler":
          await processFileForQuestionaire(rag_output.file_url,agent_session_id)
          break
      }
    },
    [],
  );

  const onControlUpdated = useCallback(
    async (payload: ControlUpdatePayload) => {
			const { file_url, agent_session_id, control, control_id, user_id } =
				payload;

      if (user_id === loggedInMember?.member_id) return;

      if (!isIdInUrl(agent_session_id)) return;

      // Get agent data for either NIST or SOC2
			const nistData = getAgentData<
				AGENT_TYPES.RISK_ASSESSMENT,
				RiskAssessmentTypes.NIST_CSF_2
			>(agent_session_id);
      const nistAIRMFData = getAgentData<
      AGENT_TYPES.RISK_ASSESSMENT,
      RiskAssessmentTypes.NIST_AI_RMF
    >(agent_session_id);
			const soc2Data = getAgentData<
				AGENT_TYPES.GAP_ASSESSMENT,
				GapAssessmentTypes.SOC2
			>(agent_session_id);
			const vendorData = getAgentData<
				AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
				MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
			>(agent_session_id);
			const fairData = getAgentData<
				AGENT_TYPES.RISK_ASSESSMENT,
				RiskAssessmentTypes.FAIR
			>(agent_session_id);

			const agentData = nistData || nistAIRMFData || soc2Data || vendorData || fairData;

      if (!agentData) return;

      const { stepData } = agentData;
			const editStepData = stepData?.find(
				(step) => step.type === AgentSessionStepType.EDIT_RESPONSE,
			);

      if (!editStepData) return;

      const updatedStep = {
        ...editStepData,
        data: {
          url: file_url,
        },
      };

      const { updateAgentStepData } = getAgentStateActions();
			const updatedSteps = stepData.map((step) =>
				step.id === updatedStep.id ? updatedStep : step,
			);

      updateAgentStepData(agent_session_id, updatedSteps);

      const orderedKeys = getOrderedKeys(agentData.agentType, control.version);

      const transformedControl = orderedKeys.map((key) => ({
        type: getRenderType(key, control.version),
        value: control[key],
        key: key,
        title: getFieldTitle(key, control.version),
        shouldRender: true,
      }));

      // Get the Map entries
			const controlArrays = Array.from(
				agentData?.mainData.reviewResponseData?.entries() ?? new Map(),
			);

      // Find the entry containing our control
      const existingControlEntry = controlArrays.find(([key, fields]) =>
        fields.some(
					(field: ReviewResponseData<keyof typeof control>) =>
						field.key === "id" && field.value === control.id,
        ),
      );

      // Handle vendor assessment updates
      if (agentData.agentType === AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT) {
        const vendorStore = useVendorRiskAssessmentStore.getState();
        const controlSection = control.section as VendorRiskSectionEnum;

        switch (controlSection) {
          case VendorRiskSectionEnum.vendor_overview: {
            const updatedOverview = [...vendorStore.vendorOverview];
						const overviewIndex = updatedOverview.findIndex(
							(item) => item.key === control.key,
						);
            if (overviewIndex !== -1) {
              if (control.key === "data_types_handled") {
                updatedOverview[overviewIndex] = {
                  ...updatedOverview[overviewIndex],
                  value: control.value,
                };
              } else {
                updatedOverview[overviewIndex] = {
                  ...updatedOverview[overviewIndex],
                  ...control,
                };
              }
              vendorStore.setVendorOverview(updatedOverview, true);
            }
            break;
          }
          case VendorRiskSectionEnum.vendor_supply_chain: {
            const updatedSupplyChain = [...vendorStore.vendorSupplyChainData];
						const supplyChainIndex = updatedSupplyChain.findIndex(
							(item) => item.key === control.key,
						);
            if (supplyChainIndex !== -1) {
              updatedSupplyChain[supplyChainIndex] = {
                ...updatedSupplyChain[supplyChainIndex],
                ...control,
                edited: true,
              };
              vendorStore.setVendorSupplyChain(updatedSupplyChain);
            }
            break;
          }
          case VendorRiskSectionEnum.zania_security_assessment:
          case VendorRiskSectionEnum.ai_security_assessment: {
            // For security assessments, we need to handle both the store update and reviewResponseData

            const updatedAssessment = [...vendorStore.vendorAssessmentData];
						const assessmentIndex = updatedAssessment.findIndex(
							(item) => item.key === control.key,
						);

            if (assessmentIndex !== -1) {
							const currentControl = updatedAssessment[
								assessmentIndex
							] as VendorRiskResponseItemForSecurityAssessment;

              // For security assessments, update reviewResponseData if it exists
              if (existingControlEntry) {
                const [mapKey, existingFields] = existingControlEntry;

                // Transform control values to match the expected format
								const updatedFields = existingFields.map(
									(field: ReviewResponseData<keyof typeof control>) => {
										if (field.key === "compliance_status") {
											return {
												...field,
												value: control.value.compliance_status,
											};
										}
										if (field.key === "observations") {
											return {
												...field,
												value: control.value.observations,
											};
										}
										if (field.key === "sources") {
											return {
												...field,
												value: control.value.sources,
											};
										}
										if (field.key === "confidence") {
											return {
												...field,
												value: control.value.confidence,
											};
										}
										if (field.key === "gaps") {
											return {
												...field,
												value: control.value.gaps,
											};
										}
										if (field.key === "recommendations") {
											return {
												...field,
												value: control.value.recommendations,
											};
										}
										return field;
									},
								);

                const newReviewResponseData = new Map(
									agentData?.mainData.reviewResponseData?.entries() ??
										new Map().entries(),
                );

                const existingData = newReviewResponseData.get(mapKey);
                // Only update if the data has actually changed
								if (
									JSON.stringify(existingData) !== JSON.stringify(updatedFields)
								) {
                  newReviewResponseData.set(mapKey, updatedFields);
                  agentData.mainData.reviewResponseData = newReviewResponseData;

                  // Update the control with all fields from the update event
									const updatedControl: VendorRiskResponseItemForSecurityAssessment =
										{
                    ...currentControl,
                    value: {
                      ...currentControl.value,
                      ...control.value,
                    },
                    edited: control.edited,
                    approved: control.approved,
                    section: control.section,
                    key: control.key,
                    question: control.question,
                    id: control.id,
                    edited_state: control.edited_state,
                    reassesed: control.reassesed,
                    selected: control.selected,
                    uid: control.uid,
                    lastUpdate: {
                      timestamp: Date.now(),
                      isRemoteUpdate: true,
                    },
                  };

                  updatedAssessment[assessmentIndex] = updatedControl;
                  vendorStore.setVendorAssessment(updatedAssessment, true);
                }
              }
            }
            break;
          }
        }

        // Update the main data for vendor assessment
        const { updateAgentData } = getAgentStateActions();
				updateAgentData<
					AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
					MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
				>(agent_session_id, {
            ...agentData,
            mainData: {
              ...agentData.mainData,
						editedIds: [
							...new Set([...agentData.mainData.editedIds, control_id]),
						],
						approvedIds: agentData.mainData.approvedIds.filter(
							(id) => id !== control_id,
						),
              lastUpdate: {
                timestamp: Date.now(),
                isRemoteUpdate: true,
              },
            },
				});
        return; // Exit early for vendor assessment
      }

			// Handle risk and soc2 updates
			if (existingControlEntry) {
				const [mapKey, existingFields] = existingControlEntry;
				let updatedFields;

				if (agentData.agentType === AGENT_TYPES.RISK_ASSESSMENT && agentData.subType === "fair_ra") {
					// Special handling for FAIR assessments
					updatedFields = existingFields.map(
						(field: ReviewResponseData<keyof typeof control>) => {
							// For FAIR assessments, we directly map the control values
							const controlValue: unknown = control[field.key];
							if (controlValue !== undefined) {
								return {
									...field,
									value: controlValue,
									// Keep the original type as it's already correct for FAIR
									type: field.type
								};
							}
							return field;
						}
					);

					// Also update table rows for rating changes
					if ('rating' in control && agentData.mainData.tableRows) {
						const tableRows = agentData.mainData.tableRows;
						const rowToUpdate = tableRows.find((row: { id?: string; rating?: string }) => row.id === control_id);
						if (rowToUpdate && 'rating' in rowToUpdate) {
							(rowToUpdate as { rating: string }).rating = control.rating as string;
							// Force a state update for the table rows
							agentData.mainData.tableRows = [...tableRows];
						}
					}
				} else {
					// Original handling for NIST and other assessments
					const orderedKeys = getOrderedKeys(agentData.agentType, control.version);
					const transformedControl = orderedKeys.map((key) => ({
						type: getRenderType(key, control.version),
						value: control[key],
						key: key,
						title: getFieldTitle(key, control.version),
						shouldRender: true,
					}));

					updatedFields = existingFields.map(
						(field: ReviewResponseData<keyof typeof control>) => {
							const matchingTransformed = transformedControl.find(
								(t) => t.key === field.key
							);
							if (matchingTransformed) {
								return {
									...field,
									value: matchingTransformed.value,
									type: matchingTransformed.type,
								};
							}
							return field;
						}
					);
				}

        // Create a new Map while preserving the same reference if data hasn't changed
				const newReviewResponseData = new Map(
					agentData?.mainData.reviewResponseData?.entries() ??
						new Map().entries(),
				);

        const existingValue = newReviewResponseData.get(mapKey);

        // Only update if the data has actually changed
        if (JSON.stringify(existingValue) !== JSON.stringify(updatedFields)) {
          newReviewResponseData.set(mapKey, updatedFields);

          const { updateAgentData } = getAgentStateActions();
          const updatedMainData = {
            ...agentData.mainData,
            reviewResponseData: newReviewResponseData,
            selectedId: agentData.mainData.selectedId,
						approvedIds: agentData.mainData.approvedIds.filter(
							(id) => id !== control_id,
						),
						editedIds: [
							...new Set([...agentData.mainData.editedIds, control_id]),
						],
            lastUpdate: {
              timestamp: Date.now(),
              isRemoteUpdate: true,
            },
          };

					if (agentData.agentType === AGENT_TYPES.RISK_ASSESSMENT) {
						if (agentData.subType === "nist-csf-2") {
							updateAgentData<
								AGENT_TYPES.RISK_ASSESSMENT,
								RiskAssessmentTypes.NIST_CSF_2
							>(agent_session_id, {
								...agentData,
								mainData: updatedMainData as RiskAgentMainData,
							});
						}else if (agentData.subType === RiskAssessmentTypes.NIST_AI_RMF) {
							updateAgentData<
								AGENT_TYPES.RISK_ASSESSMENT,
								RiskAssessmentTypes.NIST_AI_RMF
							>(agent_session_id, {
								...agentData,
								mainData: updatedMainData as RiskAgentMainData,
							});
						} else if (agentData.subType === "fair_ra") {
							updateAgentData<
								AGENT_TYPES.RISK_ASSESSMENT,
								RiskAssessmentTypes.FAIR
							>(agent_session_id, {
								...agentData,
								mainData: updatedMainData as FairAgentMainData,
							});
						}
					} else if (agentData.agentType === AGENT_TYPES.GAP_ASSESSMENT) {
						updateAgentData<
							AGENT_TYPES.GAP_ASSESSMENT,
							GapAssessmentTypes.SOC2
						>(agent_session_id, {
              ...agentData,
              mainData: updatedMainData as SOC2GapMainData,
            });
          }
        }
      }
    },
    [loggedInMember?.member_id],
  );

  // Add the reassessment started listener
	const onReassessmentStarted = useCallback(
		async ({ agent_session_id }: { agent_session_id: string }) => {
    if (!isIdInUrl(agent_session_id)) return;

    const agentData = getAgentData(agent_session_id);
    if (!agentData) return;

    const { stepData } = agentData;
    const { updateAgentStepData } = getAgentStateActions();

    // Update step status to UNDER_REASSESSMENT
    const updatedSteps = stepData.map((step) => {
      if (
					step.type === AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE || 
          step.type === AgentSessionStepType.NIST_AI_RMF ||
          step.type === AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT
      ) {
        return {
          ...step,
          status: AgentSessionStatus.UNDER_REASSESSMENT,
        };
      }
      return step;
    });
		

    updateAgentStepData(agent_session_id, updatedSteps);
		},
		[],
	);

  // Add the reassessment completed listener
  const onReassessmentCompleted = useCallback(
    async ({
      agent_session_id,
      file_url,
      member_id,
    }: { agent_session_id: string; file_url: string; member_id: string }) => {
      
      if (!isIdInUrl(agent_session_id)) return;
      
      const agentData = getAgentData(agent_session_id);
      if (!agentData) return;

      const { stepData } = agentData;
      const { updateAgentStepData } = getAgentStateActions();

      // Update step status to COMPLETE for everyone
      if (agentData.agentType === AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT) {
        const updatedSteps = stepData.map((step) => {
          if (
            step.type === AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE || 
            step.type === AgentSessionStepType.NIST_AI_RMF ||
            step.type === AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT
          ) {
            return {
              ...step,
              status: AgentSessionStatus.COMPLETE,
            };
          }
          return step;
        });

        // Update step status
        updateAgentStepData(agent_session_id, updatedSteps);
      }

      // Process the new data
      switch (agentData.agentType) {
        case AGENT_TYPES.RISK_ASSESSMENT:
          if(agentData.subType === RiskAssessmentTypes.NIST_CSF_2){
            console.log("complete rang output nist CSF 2")

            await processFileForNistRiskReview(file_url, agent_session_id);
          }
          if(agentData.subType === RiskAssessmentTypes.NIST_AI_RMF){
            console.log("complete rang output nist ai rm")
            await processFileForNistAiRMFRiskReview(file_url, agent_session_id);
          }
          break
        case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT:
					await processFileForVendorAssessmentResponse(
						file_url,
						agent_session_id,
					);
          break;
      }
    },
    [],
  );

  useEffect(() => {
		addListener<QAStructureCompletedResponse>(
			"task:qa:structure:complete",
			(data) => void onQAStructureComplete(data),
		);
    addListener<RagAuditComplete>("task:rag:complete", (data) => {
      void onRagAuditComplete(data);
    });

		addListener<ControlUpdatePayload>(
			"shared:doc:updated",
			(data) => void onControlUpdated(data),
		);
		addListener<{ agent_session_id: string }>(
			"shared:doc:reassessment:started",
			onReassessmentStarted,
		);

    addListener<{
      agent_session_id: string;
      file_url: string;
      member_id: string;
    }>("shared:doc:reassessment:completed", onReassessmentCompleted);

    return () => {
      removeListener<QAStructureCompletedResponse>(
        "task:qa:structure:complete",
        (data) => void onQAStructureComplete(data),
      );
			removeListener<ControlUpdatePayload>(
				"shared:doc:updated",
				(data) => void onControlUpdated(data),
			);
      removeListener("shared:doc:reassessment:started", onReassessmentStarted);
			removeListener(
				"shared:doc:reassessment:completed",
				onReassessmentCompleted,
			);
    };
  }, [
    addListener,
    onQAStructureComplete,
    removeListener,
    onControlUpdated,
    onRagAuditComplete,
    onReassessmentStarted,
    onReassessmentCompleted,
  ]);

  return {
    isConnected,
  };
};
