import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Tooltip,
} from "@nextui-org/react";
import {
  LoadError,
  ScrollMode,
  ViewMode,
  Viewer,
} from "@react-pdf-viewer/core";
import {
  ToolbarProps,
  ToolbarSlot,
  defaultLayoutPlugin,
} from "@react-pdf-viewer/default-layout";
import {
  HighlightArea,
  RenderHighlightsProps,
  Trigger,
  highlightPlugin,
} from "@react-pdf-viewer/highlight";
/* eslint-disable @typescript-eslint/unbound-method */
import { ReviewSourceTypes } from "@/modules/agent/types";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LoadingSpinner } from "../spin";

type PDFViewerProps = {
  fileUrl: string;
  isOpen: boolean;
  onClose: () => void;
  headerTitle: string;
  setSelectedSource: React.Dispatch<
    React.SetStateAction<ReviewSourceTypes | undefined>
  >;
  selectedSource?: ReviewSourceTypes;
  listOfSourcesForSelectedSources?: ReviewSourceTypes[];
  highlight?: HighlightArea[];
  pageNumber?: number;
};

const PDFLoader = () => {
  return (
    <div className="flex">
      <LoadingSpinner className="mx-auto" />
    </div>
  );
};

const PDFError = (error: LoadError | Error) => (
  <div className="w-full bg-white flex h-full px-24">
    <div className="grow w-full flex flex-col items-start justify-center gap-4">
      <h4 className="text-4xl font-semibold leading-[60px]">
        {error?.message ?? "Something went wrong"}
      </h4>
    </div>
  </div>
);

export default function PDFViewer({
  fileUrl,
  isOpen,
  highlight = [],
  headerTitle,
  pageNumber = 0,
  onClose,
  selectedSource,
  setSelectedSource,
  listOfSourcesForSelectedSources,
}: PDFViewerProps) {
  const transform = (slot: ToolbarSlot) => ({
    ...slot,
    // Hide specific actions
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    

    GoToLastPage: () => <></>,
    GoToNextPage: () => <></>,
    GoToPreviousPage: () => <></>,
    
    
    // Hide more actions button and all related items
    MoreActions: () => <></>,
    MoreActionsPopover: () => <></>,
    MoreActionsPopoverContent: () => <></>,
    MoreActionsMenuItem: () => <></>,
    ShowProperties: () => <></>,
    ShowPropertiesMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
    GoToFirstPageMenuItem: () => <></>,
    GoToLastPageMenuItem: () => <></>,
    GoToNextPageMenuItem: () => <></>,
    GoToPreviousPageMenuItem: () => <></>,
    RotateBackwardMenuItem: () => <></>,
    RotateForwardMenuItem: () => <></>,
    SwitchScrollModeMenuItem: () => <></>,
    SwitchSelectionModeMenuItem: () => <></>,
    ZoomInMenuItem: () => <></>,
    ZoomOutMenuItem: () => <></>,
    ShowSearchPopover: () => <></>
    
    
  });
  const renderToolbar = (Toolbar: React.ComponentType<ToolbarProps>) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    thumbnailPlugin: {
      renderSpinner: PDFLoader,
    },
    toolbarPlugin: {
      printPlugin: {
        renderProgressBar: PDFLoader,
      },
      
    },
    renderToolbar,
    sidebarTabs: () => [],
  });

  const {
    renderDefaultToolbar,
  } = defaultLayoutPluginInstance.toolbarPluginInstance;

  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const handleDocumentLoad = () => setDocumentLoaded(true);

  const [highlightRef, setHighlightRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isDocumentLoaded && highlightRef) {
      highlightRef.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isDocumentLoaded, highlightRef]);

  const renderHighlights = useCallback(
    (props: RenderHighlightsProps) => (
      <div>
        {highlight
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              ref={idx === 0 ? setHighlightRef : undefined}
              className="highlight-area"
              style={Object.assign(
                {},
                {
                  background: "yellow",
                  opacity: 0.4,
                },
                props.getCssProperties(area, props.rotation)
              )}
            />
          ))}
      </div>
    ),
    [highlight]
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const findindex = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      return (
        listOfSourcesForSelectedSources.findIndex(
          (d) => d.source_number === selectedSource?.source_number
        ) + 1
      );
    }
    return 0;
  }, [listOfSourcesForSelectedSources, selectedSource]);

  const isNextAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex < listOfSourcesForSelectedSources.length - 1;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handleNext = useCallback(() => {
    if (listOfSourcesForSelectedSources && isNextAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex + 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isNextAvailable,
    selectedSource,
  ]);

  const isPreviousAvailable = useMemo(() => {
    if (listOfSourcesForSelectedSources) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );

      return findindex > 0;
    }
    return false;
  }, [listOfSourcesForSelectedSources, selectedSource]);
  const handlePrevious = useCallback(() => {
    if (listOfSourcesForSelectedSources && isPreviousAvailable) {
      const findindex = listOfSourcesForSelectedSources.findIndex(
        (d) => d.source_number === selectedSource?.source_number
      );
      setSelectedSource(listOfSourcesForSelectedSources[findindex - 1]);
    }
  }, [
    listOfSourcesForSelectedSources,
    setSelectedSource,
    isPreviousAvailable,
    selectedSource,
  ]);

  return (
    <Modal
      className="max-h-[calc(100vh-32px)] max-w-[calc(100vw-200px)] !rounded-[8px] overflow-y-hidden !my-auto"
      style={{
        borderRadius: "8px !important",
      }}
      isOpen={isOpen}
      size="full"
      onClose={onClose}
    >
      <ModalContent className="flex flex-col">
        {() => (
          <>
            <ModalHeader className="border-b border-solid border-[#E4E4E7]">
              <div className="flex gap-3 items-center ">
                <div className="flex gap-1 items-center pr-3 border-r border-[#E4E4E7] border-solid">
                  <Tooltip
                    content="Previous Reference"
                    classNames={{
                      content:
                        "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                      arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                    }}
                  >
                    <div
                      className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                      onClick={handlePrevious}
                    >
                      <ChevronLeft
                        color={isPreviousAvailable ? "#A1A1AA" : "#E4E4E7"}
                        size={16}
                      />
                    </div>
                  </Tooltip>

                  <div className="text-[12px] leading-5 font-medium text-[#71717A] w-11 text-center">
                    {findindex} of {listOfSourcesForSelectedSources?.length}
                  </div>

                  <Tooltip
                    content="Next Reference"
                    classNames={{
                      content:
                        "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                      arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                    }}
                  >
                    <div
                      className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                      onClick={handleNext}
                    >
                      <ChevronRight
                        color={isNextAvailable ? "#A1A1AA" : "#E4E4E7"}
                        size={16}
                      />
                    </div>
                  </Tooltip>
                </div>

                <p className="text-sm font-medium">{headerTitle}</p>
              </div>
            </ModalHeader>
            <ModalBody className="grow overflow-auto px-0 py-0">
              <Viewer
                plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
                defaultScale={1}
                fileUrl={fileUrl}
                renderError={PDFError}
                renderLoader={PDFLoader}
                theme="light"
                initialRotation={0}
                enableSmoothScroll={true}
                viewMode={ViewMode.SinglePage}
                scrollMode={ScrollMode.Vertical}
                onDocumentLoad={handleDocumentLoad}
                initialPage={pageNumber ? pageNumber - 1 : 0}
              />
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
