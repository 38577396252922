import usePrevious from "@/shared/hooks/use-previous";
import { cn } from "@/shared/utils/classname-merger";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CellPosition,
  ExcelItemTypes,
  ExcelMapperProps,
  Selection,
} from "../../types/excel-mapper";
import {
  getNewSelection,
  isSameSelection,
  isSelectionAllowed,
} from "../../utils/excel-mapper";
import ExcelMapperCell from "./cell";
import ExcelHeader from "./header";

const ExcelMapper: FC<ExcelMapperProps> = (props) => {
  const {
    selectedSheetData: { selectedSheet, data: selectedSheetData, merges },
    sheetNames,
    highlightedColumn,
    highlightedRow,
    highlightedCell,
    allowSelection,
    customHeader,
    showHeader = true,
    setSelectedSheet,
    onSelectionChange,
    resetSelectionOnChange,
    scrollToRowSelection = false,
  } = props;

  const prevAllowSelection = usePrevious(allowSelection);

  const [selection, setSelection] = useState<Selection | undefined>(undefined);
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState<CellPosition | undefined>(
    undefined,
  );
  const [dragEnd, setDragEnd] = useState<CellPosition | undefined>(undefined);
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (
      scrollToRowSelection &&
      highlightedRow &&
      highlightedRow.size > 0 &&
      tableBodyRef.current
    ) {
      setTimeout(() => {
        const rows =
          tableBodyRef.current?.querySelectorAll("[data-highlighted]");
        if (rows && rows.length > 0) {
          rows[0].scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
    }
  }, [scrollToRowSelection, highlightedRow]);

  const commonCellStyle = "min-w-[200px] max-w-[300px]";

  const selectionTypes = useMemo(
    () =>
      new Set(
        allowSelection
          ? props.selectionType === "all"
            ? ["all"]
            : props.selectionType
          : [],
      ),
    [allowSelection, props],
  );

  const resetState = useCallback(() => {
    setSelection(undefined);
    setDragging(false);
    setDragStart(undefined);
    setDragEnd(undefined);
  }, []);

  useEffect(() => {
    if (resetSelectionOnChange && allowSelection !== prevAllowSelection) {
      resetState();
    }
  }, [allowSelection, prevAllowSelection, resetSelectionOnChange, resetState]);

  const handleSelection = useCallback(
    (e: MouseEvent<HTMLTableCellElement>) => {
      if (!allowSelection) return;

      const getTypeAttribute = (element: HTMLElement, attr: string) =>
        element.getAttribute(attr);

      const type = getTypeAttribute(
        e.currentTarget,
        "data-type",
      ) as ExcelItemTypes;
      const selectionId = getTypeAttribute(e.currentTarget, "data-selectionid");
      const column = getTypeAttribute(e.currentTarget, "data-col");

      const newSelectionObj = getNewSelection(
        type,
        selectionId,
        column,
        selectionTypes,
        selection,
      );

      if (newSelectionObj) {
        if (selection && isSameSelection(selection, newSelectionObj)) {
          setSelection(undefined);
          onSelectionChange?.(undefined);
        } else {
          setSelection(newSelectionObj);
          onSelectionChange?.(newSelectionObj);
        }
      }
    },
    [allowSelection, onSelectionChange, selection, selectionTypes],
  );

  const handleSheetChange = useCallback(
    (sheetName: string) => {
      setSelectedSheet(sheetName);
      resetState();
    },
    [resetState, setSelectedSheet],
  );

  const handleMouseDown = useCallback(
    (rowIndex: number, columnIndex: number) => {
      if (!isSelectionAllowed(ExcelItemTypes.MULTI_CELL_DRAG, selectionTypes))
        return;
      setDragging(true);
      setDragStart({ rowIndex, columnIndex });
      setDragEnd({ rowIndex, columnIndex });
    },
    [selectionTypes],
  );

  const handleMouseUp = useCallback(() => {
    if (!isSelectionAllowed(ExcelItemTypes.MULTI_CELL_DRAG, selectionTypes))
      return;
    if (
      dragStart &&
      dragEnd &&
      !(
        dragStart?.rowIndex === dragEnd?.rowIndex &&
        dragStart?.columnIndex === dragEnd?.columnIndex
      )
    ) {
      const cells = [];
      for (let i = dragStart.rowIndex; i <= dragEnd.rowIndex; i++) {
        for (let j = dragStart.columnIndex; j <= dragEnd.columnIndex; j++) {
          cells.push(`${i}-${j}`);
        }
      }
      setSelection({
        type: ExcelItemTypes.MULTI_CELL_DRAG,
        start: dragStart,
        end: dragEnd,
        cells,
      });
    }
    setDragging(false);
    setDragStart(undefined);
    setDragEnd(undefined);
  }, [selectionTypes, dragStart, dragEnd]);

  const handleMouseMove = useCallback(
    (rowIndex: number, columnIndex: number) => {
      if (!isSelectionAllowed(ExcelItemTypes.MULTI_CELL_DRAG, selectionTypes))
        return;
      if (dragging) {
        setDragEnd({ rowIndex, columnIndex });
      }
    },
    [dragging, selectionTypes],
  );

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [selectedSheet]);

  const getHeaderRowIndex = useCallback((data: string[][]) => {
    let emptyRowCount = 0;
    for (let i = 0; i < data.length; i++) {
      const isEmptyRow = data[i].every(cell => !cell || cell?.toString()?.trim() === '');
      if (isEmptyRow) {
        emptyRowCount++;
      }
      if (data[i].some(cell => cell?.toString()?.trim() !== '')) {
        return i + (emptyRowCount - 1);
      }
    }
    return emptyRowCount;
  }, []);

  const headerRowIndex = useMemo(() => 
    getHeaderRowIndex(selectedSheetData), 
    [selectedSheetData, getHeaderRowIndex]
  );

  console.log("selectedSheetData",selectedSheetData)
  
  return (
    <div className="h-full w-full flex flex-col gap-4">
      <div className="w-full  h-full rounded-md  border-b  relative flex flex-col">
        {sheetNames && showHeader ? (
          !customHeader ? (
            <ExcelHeader
              selectedSheet={selectedSheet}
              sheetNames={sheetNames}
              handleSheetChange={handleSheetChange}
            />
          ) : (
            customHeader
          )
        ) : null}
        <div
          ref={tableContainerRef}
          className="w-full overflow-auto overflow-y-scroll overflow-x-auto grow"
        >
          <table className="h-full min-w-max w-full overflow-auto overflow-y-scroll overflow-x-auto border-separate border-spacing-0 text-sm">
            <thead className="sticky top-0 text-tiny">
              <tr>
                <th
                  className={cn(
                    "bg-[#F9F9FA] max-w-[35px] w-[35px] border-r-[1px]",
                  )}
                ></th>
                {selectedSheetData[0].map((_, index) => {
                  const isHighlighted = highlightedColumn?.get(index);
                  const style = isHighlighted
                    ? {
                        backgroundColor: isHighlighted?.backgroundColor,
                      }
                    : {};

                  return (
                    <th
                      key={index}
                      data-col={index}
                      data-selectionid={index}
                      data-type={
                        isSelectionAllowed(
                          ExcelItemTypes.MULTI_COLUMN,
                          selectionTypes,
                        )
                          ? ExcelItemTypes.MULTI_COLUMN
                          : ExcelItemTypes.COLUMN
                      }
                      onClick={handleSelection}
                      className={cn(
                        commonCellStyle,
                        "select-none [&:not(:last-child)]:border-r-[1px] bg-[#F9F9FA] border-b-[1px]",
                        "h-full text-center p-2",
                        !!allowSelection && "cursor-pointer",
                      )}
                      style={style}
                    >
                      {(index + 10).toString(36).toUpperCase()}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody ref={tableBodyRef}>
              {selectedSheetData.map((row, arrayIndex) => {
                const actualRowNumber = arrayIndex + headerRowIndex + 1;
                const isHighlighted = highlightedRow?.get(actualRowNumber);
                const rowStyle = isHighlighted
                  ? {
                      backgroundColor: isHighlighted.backgroundColor,
                    }
                  : {};
                return (
                  <tr
                    key={arrayIndex}
                    className="hover:bg-color-none"
                    style={rowStyle}
                    {...(isHighlighted ? { "data-highlighted": "true" } : {})}
                  >
                    <td
                      className={cn(
                        "border-r-[1px] text-tiny border-border max-w-[40px] &:not(:last-child)]:border-b-[1px] select-none w-[40px] text-center p-2",
                        isSelectionAllowed(
                          ExcelItemTypes.ROW,
                          selectionTypes,
                        ) && "cursor-pointer",
                      )}
                      data-selectionid={actualRowNumber}
                      data-type={ExcelItemTypes.ROW}
                      onClick={handleSelection}
                      style={
                        isHighlighted
                          ? {
                              borderColor: isHighlighted.borderColor,
                              borderWidth: "1px",
                              // borderStyle: 'dashed',
                            }
                          : {}
                      }
                    >
                      {actualRowNumber}
                    </td>
                    {row.map((cell, columnIndex) => (
                      <ExcelMapperCell
                        key={columnIndex}
                        cell={cell}
                        rowIndex={arrayIndex}
                        columnIndex={columnIndex}
                        handleSelection={handleSelection}
                        selection={selection}
                        handleMouseDown={handleMouseDown}
                        handleMouseUp={handleMouseUp}
                        handleMouseMove={handleMouseMove}
                        dragStart={dragStart}
                        dragEnd={dragEnd}
                        merges={merges}
                        dragging={dragging}
                        isHighlighted={isHighlighted}
                        allowSelection={allowSelection}
                        isFirstRow={arrayIndex === 0}
                        isLastRow={arrayIndex === selectedSheetData.length - 1}
                      />
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExcelMapper;
